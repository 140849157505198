import React, {ReactNode} from "react";
import {useWindowSize} from "@ch/foundations/hooks/useWindowSize";
import {SafeAreaView, ScrollView} from "react-native";
import {tailwind} from "@ch/foundations/styles";
import {FallbackPageUI} from "@ch/foundations/ui";
import {ErrorBoundary} from "@sentry/nextjs";
import {CollapsibleNav, UserBoundary} from "..";
import {NavState, useSettings} from "@ch/foundations/hooks/useSettings";

// Based on small tablet widths
const NAV_TOGGLE_WIDTH = 768;

type DashboardLayoutProps = {
  children: ReactNode;
};
/**
 * The Dashboard layout includes a side navigation which can be either expanded or collapsed
 * The size of this side navigation can be controlled by 1) the user or 2) the size of the window
 *
 * In cases where the user has not set the navigation size (i.e. clicked the button at some point)
 * we want the nav to respond to the size of the window. However, if a user has toggled it, we want
 * to honor their choice and show it expanded / collapsed.
 *
 * This presents two states for the navigation size:
 * 1. Expanded
 * 2. Collapsed
 *
 *
 * The navigation size is kept in local storage and is only changed their on a user's action.
 * Within this component, we keep a calculated navigation state based on the preference + window size
 *
 */
export default function DashboardLayout({children}: DashboardLayoutProps) {
  const windowWidth = useWindowSize().width;
  const stateFromWindow = windowWidth > NAV_TOGGLE_WIDTH ? NavState.Expanded : NavState.Collapsed;

  const {settings, writeSetting, loading} = useSettings();

  // if the user has toggled the navigation we use it or we fallback to the window size
  const navState = settings?.navState || stateFromWindow;

  if (loading) return null;

  return (
    <SafeAreaView style={tailwind("flex flex-row h-full items-center")}>
      <UserBoundary>
        <CollapsibleNav
          expanded={navState === NavState.Expanded}
          onExpandToggle={expanded =>
            writeSetting("navState", expanded ? NavState.Expanded : NavState.Collapsed)
          }
        />
        <ScrollView
          style={tailwind("h-full")}
          contentContainerStyle={tailwind("h-full flex-1 overflow-scroll")}
          accessibilityRole="main"
        >
          <ErrorBoundary fallback={FallbackPageUI}>{children}</ErrorBoundary>
        </ScrollView>
      </UserBoundary>
    </SafeAreaView>
  );
}
