import React, {ReactNode} from "react";
import {OrganizationType} from "../types/__generated__/graphql";
import {useQuery, gql} from "@apollo/client";
import {EnterpriseDashboardOrganizationTypeQuery} from "./__generated__/OrgTypeBoundarytypes";
import useDeepCompareMemo from "../lib/foundations/hooks/useDeepCompareMemo";

export const ORG_TYPE = gql`
  query EnterpriseDashboardOrganizationType {
    me {
      ... on OrganizationAdminUser {
        organization {
          id # for caching
          typ
        }
      }
    }
  }
`;

type OrgTypeBoundaryProps = {
  children: ReactNode;
  // only render components when the org type matches one of these
  // while loading, won't render children
  showFor: Array<OrganizationType | null>;
};

export default function OrgTypeBoundary({children, showFor}: OrgTypeBoundaryProps) {
  const {data, loading} = useQuery<EnterpriseDashboardOrganizationTypeQuery>(ORG_TYPE);
  const typ =
    (data?.me?.__typename === "OrganizationAdminUser" ? data.me.organization?.typ : null) || null;

  // this small computation could be repeated a ton of times
  // - if loading, don't show children.
  // - if showFor list, check to make sure org typ is in the allow list
  // - if not, don't render children
  const showChildren = useDeepCompareMemo<boolean>(() => {
    return !loading && (showFor.length ? showFor.includes(typ) : false);
  }, [typ, loading, showFor]);

  return showChildren ? <>{children}</> : null;
}
