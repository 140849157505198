import {View} from "react-native";
import {tailwind} from "@ch/foundations/styles";
import {Heading, Paragraph, Subtitle} from "@ch/foundations/ui/";
import {Image} from "../lib/Image";
import OnePlaceLg from "../../public/static/images/OnePlace-lg.png";
type DashboardErrorType = "auth" | "network" | "not-found";

type DashboardErrorProps = {
  type: DashboardErrorType;
};

export default function DashboardError(props: DashboardErrorProps) {
  const renderErrorMessage = () => {
    switch (props.type) {
      case "auth": {
        return (
          <>
            <Subtitle weight="bold" style={tailwind("mt-8")}>
              Oops!
            </Subtitle>
            <Paragraph style={tailwind("mt-2")}>
              Looks like you don’t have access to this page anymore.
            </Paragraph>
            <Paragraph>Contact your admin if this is a mistake.</Paragraph>
          </>
        );
      }

      case "network": {
        return (
          <>
            <Subtitle weight="bold" style={tailwind("mt-8")}>
              Oops!
            </Subtitle>
            <Paragraph style={tailwind("mt-2")}>
              An error occurred when loading this page.
            </Paragraph>
            <Paragraph>Try reloading the page or signing in again.</Paragraph>
            <Paragraph>If this problem persists, please contact support.</Paragraph>
          </>
        );
      }

      case "not-found": {
        return (
          <>
            <Heading
              weight="bold"
              accessibilityRole="heading"
              level={6}
              accessibilityLevel={1}
              style={tailwind("mt-8")}
            >
              Oops! Looks like you’re lost.
            </Heading>
            <Paragraph style={tailwind("mt-5")}>
              {"The page you’re looking for wasn’t found."}
            </Paragraph>
          </>
        );
      }
    }
  };

  return (
    <View style={tailwind("w-full h-full flex-col justify-center items-center")}>
      <Image src={OnePlaceLg} alt="A person standing by a bike checking their phone" />
      {renderErrorMessage()}
    </View>
  );
}
