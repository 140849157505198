// https://github.com/sandiiarov/use-deep-compare/blob/master/src/useDeepCompareMemoize.ts

import React from "react";
import equal from "fast-deep-equal/es6/react";

/**
 * from: https://github.com/kentcdodds/use-deep-compare-effect/blob/86d1b707066a468ce8ce674685469ec454b2987a/src/index.ts#L24
 * The idea here is, primitive values are: string , number , bigint , boolean , undefined , symbol , and null.
 * `s` in the regex takes care of string and symbol.
 * `b` takes care of bigint and boolean
 * `n` takes care of number
 */
function isPrimitive(val: unknown) {
  return val == null || /^[sbn]/.test(typeof val);
}

export function shouldUseBaseHook(deps?: React.DependencyList): boolean {
  if (!deps) return true;
  const isDepsEmpty = deps.length === 0;
  const areAllDepsPrimitive = deps.every(isPrimitive);
  return isDepsEmpty || areAllDepsPrimitive;
}

export function useDeepCompareMemoize(value?: React.DependencyList): React.DependencyList {
  const ref = React.useRef<React.DependencyList>([]);

  if (value && !equal(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}
