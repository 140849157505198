import {useRouter} from "next/dist/client/router";
import {useEffect, ReactNode} from "react";
import {useAuth} from "@ch/foundations/hooks/useAuth";

export const REDIRECT_PATH = "/sign-in";

type UserBoundaryProps = {
  children: ReactNode;
  redirect?: boolean;
};

export default function UserBoundary({children, redirect}: UserBoundaryProps) {
  const {user, loading} = useAuth();
  const router = useRouter();

  useEffect(() => {
    if (loading || user) return;
    if (redirect !== false) {
      router.push(`${REDIRECT_PATH}?rdp=${router.asPath}`);
    }
  }, [user, loading, router, redirect]);

  if (user) return <>{children}</>;

  return null;
}
