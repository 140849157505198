export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends {[key: string]: unknown}> = {[K in keyof T]: T[K]};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {[SubKey in K]?: Maybe<T[SubKey]>};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {[SubKey in K]: Maybe<T[SubKey]>};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  /**
   * This is essentially an Any type that needs to be
   * deserialized and checked on the client
   */
  FeatureFlagResponseBlob: any;
  JSON: any;
};

export type Action = {
  __typename?: "Action";
  name?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

export type Actionable = {
  callToAction?: Maybe<Scalars["String"]>;
  dueDate?: Maybe<Scalars["DateTime"]>;
  id: Scalars["ID"];
  prompt?: Maybe<Scalars["String"]>;
  state?: Maybe<ActionableState>;
  stateTime?: Maybe<Scalars["DateTime"]>;
  subtext?: Maybe<Scalars["String"]>;
};

/** A grouping of a filter and an operator that allows us to chain filters together */
export type ActionableClause = {
  filter: ActionableFilter;
  operator: Operator;
};

/**
 * A filter that applies to actionables. Only one of these should be set at a time,
 * and we can use Operators to join multiple filters
 */
export type ActionableFilter = {
  programId?: InputMaybe<Scalars["ID"]>;
  specialtyId?: InputMaybe<Scalars["ID"]>;
};

/**
 * The root filtering object, that contains the top level filter, and then
 * multiple clauses to create a general query
 */
export type ActionableFilters = {
  clauses?: InputMaybe<Array<ActionableClause>>;
  root: ActionableFilter;
};

export enum ActionableState {
  Active = "ACTIVE",
  Completed = "COMPLETED",
  Dismissed = "DISMISSED",
  Expired = "EXPIRED",
  Invalidated = "INVALIDATED",
  Snoozed = "SNOOZED",
  Unspecified = "UNSPECIFIED",
}

export type ActionablesConnection = Connection & {
  __typename?: "ActionablesConnection";
  nodes?: Maybe<Array<Maybe<Actionable>>>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type Actor = {
  __typename?: "Actor";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

export type Appointment = Node & {
  __typename?: "Appointment";
  case?: Maybe<Case>;
  date?: Maybe<Scalars["DateTime"]>;
  id: Scalars["ID"];
  status?: Maybe<AppointmentStatus>;
};

/** A reason that a patient can book an appointment. These will vary from one Practice to the next. */
export type AppointmentReason = Node & {
  __typename?: "AppointmentReason";
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
};

export enum AppointmentStatus {
  CallStarted = "CALL_STARTED",
  Canceled = "CANCELED",
  CheckedIn = "CHECKED_IN",
  Finished = "FINISHED",
  Skipped = "SKIPPED",
  Started = "STARTED",
  TriageCompleted = "TRIAGE_COMPLETED",
  TriageStarted = "TRIAGE_STARTED",
  Upcoming = "UPCOMING",
}

export type AppointmentsBySpecialtiesFilterInput = {
  specialtyIds: Array<Scalars["ID"]>;
};

export type AppointmentsConnection = Connection & {
  __typename?: "AppointmentsConnection";
  nodes?: Maybe<Array<Maybe<Appointment>>>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars["Int"]>;
};

/** The result of approveRule mutation */
export type ApproveRuleResponse = MutationResponse & {
  __typename?: "ApproveRuleResponse";
  /**
   * When defined this list will contain error codes for this operation.
   * TODO: enumerate the possible error codes
   */
  codes?: Maybe<Array<Scalars["String"]>>;
  /** @deprecated based on @jackson dawkins rfc https://docs.google.com/document/d/1bYGuMtjfcTQYR7OZQfe_F6s_kU5av5JpxlvqcBCJWJE/edit#heading=h.p36yikvv4579 */
  message?: Maybe<Scalars["String"]>;
  /** The updated rule if operation is successful */
  rule?: Maybe<Rule>;
  /** Overall success of the mutation. If there are any errors, this should be false */
  success: Scalars["Boolean"];
};

export type ApptReason = Node & {
  __typename?: "ApptReason";
  id: Scalars["ID"];
};

export type AuthenticationError = {
  __typename?: "AuthenticationError";
  code?: Maybe<Scalars["String"]>;
  message?: Maybe<Scalars["String"]>;
};

export type Bill = Node & {
  __typename?: "Bill";
  chargedAmount?: Maybe<Scalars["String"]>;
  dateOfService?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  insuranceBalance?: Maybe<Scalars["String"]>;
  location?: Maybe<Location>;
  patient?: Maybe<Patient>;
  patientBalance?: Maybe<Scalars["String"]>;
  payers?: Maybe<Payers>;
  provider?: Maybe<Provider>;
  responsibleParty?: Maybe<ResponsiblePartyType>;
  specialty?: Maybe<Specialty>;
  status?: Maybe<Scalars["String"]>;
};

export type BillExecutionJob = Node & {
  __typename?: "BillExecutionJob";
  bill: Bill;
  completedAt?: Maybe<Scalars["DateTime"]>;
  errorCode?: Maybe<JobErrorCode>;
  id: Scalars["ID"];
  status: BillExecutionJobStatus;
  /** @deprecated Use errorCode instead and craft your own human-friendly message on the clients */
  statusDescription?: Maybe<Scalars["String"]>;
};

export enum BillExecutionJobStatus {
  Error = "ERROR",
  Pending = "PENDING",
  Success = "SUCCESS",
}

export type BillExecutionJobsConnection = Connection & {
  __typename?: "BillExecutionJobsConnection";
  nodes?: Maybe<Array<Maybe<BillExecutionJob>>>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars["Int"]>;
};

/**
 * Filter used to search for bills that are
 * apart of a specific bill status or at the
 * reason level.
 */
export type BillStatusReasonFilter = {
  __typename?: "BillStatusReasonFilter";
  /** bill status reason */
  reasons?: Maybe<Array<Scalars["String"]>>;
  /** bill status */
  status: Scalars["String"];
};

/**
 * Filter used to search for bills that are
 * apart of a specific bill status or at the
 * reason level.
 */
export type BillStatusReasonFilterInput = {
  /** bill status reason */
  reasons?: InputMaybe<Array<Scalars["String"]>>;
  /** bill status */
  status: Scalars["String"];
};

export type BillingUser = Node & {
  __typename?: "BillingUser";
  bills?: Maybe<BillsConnection>;
  billsExport?: Maybe<Array<Maybe<Bill>>>;
  firstName?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  lastName?: Maybe<Scalars["String"]>;
  rule?: Maybe<Rule>;
  rules?: Maybe<RulesConnection>;
};

export type BillingUserBillsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<BillsFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type BillingUserBillsExportArgs = {
  filter?: InputMaybe<BillsFilterInput>;
};

export type BillingUserRuleArgs = {
  ruleId?: InputMaybe<Scalars["String"]>;
};

export type BillingUserRulesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type BillsConnection = Connection & {
  __typename?: "BillsConnection";
  nodes?: Maybe<Array<Maybe<Bill>>>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type BillsFilter = {
  __typename?: "BillsFilter";
  /** list of bill ids */
  billIds?: Maybe<Array<Scalars["String"]>>;
  /** from and to in format of YYYY-MM-DD */
  claimSubmissionDate?: Maybe<DateRange>;
  /** codes filter */
  codes?: Maybe<CodesFilter>;
  /** from and to in format of YYYY-MM-DD */
  creationDate?: Maybe<DateRange>;
  /** from and to in format of YYYY-MM-DD */
  dateOfService?: Maybe<DateRange>;
  /** applied financial class and subclass filter */
  financialClasses?: Maybe<Array<FinancialClassFilter>>;
  /**
   * List of Group NPIs.
   *
   * The National Provider Identifier (NPI) of the organization or group practice
   */
  groupNpis?: Maybe<Array<Scalars["String"]>>;
  /** Please note the values are in cents. USD only. */
  insuranceBalance?: Maybe<IntRange>;
  isCreditCardOnFile?: Maybe<Scalars["Boolean"]>;
  isPatientInvoiced?: Maybe<Scalars["Boolean"]>;
  /**
   * location ids filters adapted to location shape to allow for further resolving
   * @deprecated We are moving to locationsFilter.
   */
  locations?: Maybe<Array<Location>>;
  /** location filter */
  locationsFilter?: Maybe<LocationsFilter>;
  /** Please note the values are in cents. USD only. */
  patientBalance?: Maybe<IntRange>;
  payers?: Maybe<PayersFilter>;
  /** practice slug filter adapted to practice shape to allow for further resolving */
  practices?: Maybe<Array<Practice>>;
  providerName?: Maybe<Scalars["String"]>;
  /** applied filter of providers */
  providers?: Maybe<Array<Provider>>;
  responsibleParties?: Maybe<Array<ResponsiblePartyType>>;
  searchText?: Maybe<Scalars["String"]>;
  specialties?: Maybe<Array<Specialty>>;
  /** list of bill status reasons */
  statusReasons?: Maybe<Array<BillStatusReasonFilter>>;
};

export type BillsFilterInput = {
  /** list of bill ids */
  billIds?: InputMaybe<Array<Scalars["String"]>>;
  /** from and to in format of YYYY-MM-DD */
  claimSubmissionDate?: InputMaybe<DateRangeInput>;
  /** codes filter */
  codes?: InputMaybe<CodesFilterInput>;
  /** from and to in format of YYYY-MM-DD */
  creationDate?: InputMaybe<DateRangeInput>;
  /** from and to in format of YYYY-MM-DD */
  dateOfService?: InputMaybe<DateRangeInput>;
  /** financial class and subclass filter to apply */
  financialClasses?: InputMaybe<Array<FinancialClassFilterInput>>;
  /**
   * List of Group NPIs.
   *
   * The National Provider Identifier (NPI) of the organization or group practice
   */
  groupNpis?: InputMaybe<Array<Scalars["String"]>>;
  /** Please note the values are in cents. USD only. */
  insuranceBalance?: InputMaybe<IntRangeInput>;
  isCreditCardOnFile?: InputMaybe<Scalars["Boolean"]>;
  isPatientInvoiced?: InputMaybe<Scalars["Boolean"]>;
  /**
   * deprecated (use locations filter instead)
   *
   * list of location ids
   */
  locationIds?: InputMaybe<Array<Scalars["ID"]>>;
  /** location filter */
  locations?: InputMaybe<LocationsFilterInput>;
  /** Please note the values are in cents. USD only. */
  patientBalance?: InputMaybe<IntRangeInput>;
  payers?: InputMaybe<PayersFilterInput>;
  /** provides list of practice slugs as filters */
  practices?: InputMaybe<PracticesFilterInput>;
  providerName?: InputMaybe<Scalars["String"]>;
  /**
   * Deprecated. use providers for same capability
   *
   * List of providers npi (National Provider Identifier)
   * Ref: https://npiregistry.cms.hhs.gov/
   */
  providerNpis?: InputMaybe<Array<Scalars["String"]>>;
  /** providers filter */
  providers?: InputMaybe<ProvidersFilterInput>;
  responsibleParties?: InputMaybe<Array<ResponsiblePartyType>>;
  searchText?: InputMaybe<Scalars["String"]>;
  specialtyIds?: InputMaybe<Array<Scalars["ID"]>>;
  /** list of bill status reasons */
  statusReasons?: InputMaybe<Array<BillStatusReasonFilterInput>>;
};

/**
 * An actionable that prompts the patient to book an appointment.
 * The actionable may specify multiple appointment reasons, specialties and a
 * treatment plan.
 */
export type BookAppointmentActionable = Actionable &
  Node & {
    __typename?: "BookAppointmentActionable";
    apptReasons?: Maybe<Array<Maybe<ApptReason>>>;
    callToAction?: Maybe<Scalars["String"]>;
    dueDate?: Maybe<Scalars["DateTime"]>;
    id: Scalars["ID"];
    prompt?: Maybe<Scalars["String"]>;
    specialties?: Maybe<Array<Maybe<Specialty>>>;
    state?: Maybe<ActionableState>;
    stateTime?: Maybe<Scalars["DateTime"]>;
    subtext?: Maybe<Scalars["String"]>;
    treatmentPlan?: Maybe<TreatmentPlan>;
  };

export type Carrier = {
  __typename?: "Carrier";
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
};

export type Case = Node & {
  __typename?: "Case";
  id: Scalars["ID"];
  reports?: Maybe<CaseReportsConnection>;
  title?: Maybe<Scalars["String"]>;
};

export type CaseReportsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filters?: InputMaybe<CaseReportsFilterInput>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** A report/form associated with a Case. */
export type CaseReport = Node & {
  __typename?: "CaseReport";
  documents?: Maybe<DocumentsConnection>;
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
};

/** A report/form associated with a Case. */
export type CaseReportDocumentsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** List of CaseReports and metadata. */
export type CaseReportsConnection = Connection & {
  __typename?: "CaseReportsConnection";
  nodes?: Maybe<Array<Maybe<CaseReport>>>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars["Int"]>;
};

/** Input for filtering a Case's reports. */
export type CaseReportsFilterInput = {
  entityIds?: InputMaybe<Array<Scalars["ID"]>>;
};

/** Parameter values for rules that contain an action of Change Payer */
export type ChangePayerParameters = {
  __typename?: "ChangePayerParameters";
  /** The Carrier that bills where changed to */
  carrier: Carrier;
};

/** Parameter values for rules that contain an action of Change Payer */
export type ChangePayerParametersInput = {
  /** Carrier's DB unique identifier. Needed to resolve the carrier's name upon querying the action. */
  carrierId: Scalars["ID"];
  /** Carrier's claim payer ID assigned as the bill's payer */
  claimPayerId: Scalars["String"];
};

/** The description of configs used to change a bill's status */
export type ChangeStatusParameters = {
  __typename?: "ChangeStatusParameters";
  reason?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
};

/** The configs needed to change a bill's status */
export type ChangeStatusParametersInput = {
  reason?: InputMaybe<Scalars["String"]>;
  status: Scalars["String"];
};

/**
 * Filter to search for bills that are
 * associated with a certain codes
 */
export type CodesFilter = {
  __typename?: "CodesFilter";
  /** These codes categorize a payment adjustment. */
  claimAdjustmentGroups?: Maybe<Array<Scalars["String"]>>;
  /** These codes describe why a claim or service line was paid differently than it was billed. */
  claimAdjustmentReasons?: Maybe<Array<Scalars["String"]>>;
  /**
   * CPT (Current Procedural Terminology)
   * are numbers assigned to each task and service a healthcare provider offers
   */
  cpt?: Maybe<Array<Scalars["String"]>>;
  /**
   * DX (Diagnosis codes)
   * describe an individual's medical condition and are required on claims submitted by
   * health care professionals to third party payers
   */
  dx?: Maybe<Array<Scalars["String"]>>;
};

/**
 * Input filter to search for bills that are
 * associated with a certain codes
 */
export type CodesFilterInput = {
  /** These codes categorize a payment adjustment. */
  claimAdjustmentGroup?: InputMaybe<Array<Scalars["String"]>>;
  /** These codes describe why a claim or service line was paid differently than it was billed. */
  claimAdjustmentReason?: InputMaybe<Array<Scalars["String"]>>;
  /**
   * CPT (Current Procedural Terminology)
   * are numbers assigned to each task and service a healthcare provider offers
   */
  cpt?: InputMaybe<Array<Scalars["String"]>>;
  /**
   * DX (Diagnosis codes)
   * describe an individual's medical condition and are required on claims submitted by
   * health care professionals to third party payers
   */
  dx?: InputMaybe<Array<Scalars["String"]>>;
};

export type CompleteActionableInput = {
  actionableId: Scalars["ID"];
  patientId: Scalars["ID"];
};

export type CompleteActionableResponse = MutationResponse & {
  __typename?: "CompleteActionableResponse";
  actionable?: Maybe<Actionable>;
  message?: Maybe<Scalars["String"]>;
  patient?: Maybe<Patient>;
  success: Scalars["Boolean"];
};

/** An actionable that prompts the patient to complete HPI. */
export type CompleteHpiActionable = Actionable &
  Node & {
    __typename?: "CompleteHPIActionable";
    appt?: Maybe<Appointment>;
    callToAction?: Maybe<Scalars["String"]>;
    dueDate?: Maybe<Scalars["DateTime"]>;
    id: Scalars["ID"];
    prompt?: Maybe<Scalars["String"]>;
    state?: Maybe<ActionableState>;
    stateTime?: Maybe<Scalars["DateTime"]>;
    subtext?: Maybe<Scalars["String"]>;
  };

export type Condition = {
  __typename?: "Condition";
  filter: BillsFilter;
};

export type ConditionInput = {
  filter: BillsFilterInput;
};

export type Connection = {
  nodes?: Maybe<Array<Maybe<Node>>>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type ConsentError = {
  __typename?: "ConsentError";
  code?: Maybe<Scalars["String"]>;
  message?: Maybe<Scalars["String"]>;
};

export type CovidHomeTestKitOrder = Node & {
  __typename?: "CovidHomeTestKitOrder";
  deliveredToLabTime?: Maybe<Scalars["DateTime"]>;
  deliveryMethod?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  member?: Maybe<OrganizationMember>;
  orderDate: Scalars["DateTime"];
  pickedUpFromPatientTime?: Maybe<Scalars["DateTime"]>;
  result?: Maybe<CovidLabResult>;
  returnDeliveryMethod?: Maybe<Scalars["String"]>;
  returnTrackingNumber?: Maybe<Scalars["String"]>;
  returnTrackingUrl?: Maybe<Scalars["String"]>;
  specimenCollectionTime?: Maybe<Scalars["DateTime"]>;
  status: Scalars["String"];
  trackingNumber?: Maybe<Scalars["String"]>;
  trackingUrl?: Maybe<Scalars["String"]>;
};

export type CovidHomeTestKitOrdersConnection = Connection & {
  __typename?: "CovidHomeTestKitOrdersConnection";
  nodes?: Maybe<Array<Maybe<CovidHomeTestKitOrder>>>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type CovidLabResult = {
  __typename?: "CovidLabResult";
  image?: Maybe<Image>;
  result?: Maybe<CovidTestLabResults>;
  resultTime?: Maybe<Scalars["DateTime"]>;
  testName?: Maybe<Scalars["String"]>;
  timeType?: Maybe<TestTimeType>;
};

export type CovidTest = Node & {
  __typename?: "CovidTest";
  id: Scalars["ID"];
  member?: Maybe<OrganizationMember>;
  testName?: Maybe<CovidTestName>;
  testResult?: Maybe<TestResult>;
  testResultTime?: Maybe<Scalars["DateTime"]>;
  testTakenTime?: Maybe<Scalars["DateTime"]>;
};

export enum CovidTestLabResults {
  Negative = "NEGATIVE",
  Positive = "POSITIVE",
  Unknown = "UNKNOWN",
}

export enum CovidTestName {
  CarbonReviewedExternalTest = "CARBON_REVIEWED_EXTERNAL_TEST",
  HomeTestKit = "HOME_TEST_KIT",
  HomeTestKitPcr = "HOME_TEST_KIT_PCR",
  Pcr = "PCR",
  RapidAntigen = "RAPID_ANTIGEN",
  RapidPcr = "RAPID_PCR",
  SelfReportedExternalTest = "SELF_REPORTED_EXTERNAL_TEST",
  Unknown = "UNKNOWN",
}

export type CovidTestingStats = {
  __typename?: "CovidTestingStats";
  membersTestedCount: Scalars["Int"];
  negativeTestCount: Scalars["Int"];
  positiveTestCount: Scalars["Int"];
};

export type CovidTestsConnection = Connection & {
  __typename?: "CovidTestsConnection";
  nodes?: Maybe<Array<Maybe<CovidTest>>>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type CovidVaccination = {
  __typename?: "CovidVaccination";
  doses?: Maybe<Array<Maybe<Dose>>>;
  exemption?: Maybe<ExemptionType>;
  exemptionImage?: Maybe<Image>;
  status?: Maybe<CovidVaccinationCompletionStatus>;
  vaccineCardImage?: Maybe<Image>;
};

export enum CovidVaccinationCompletionStatus {
  Exempt = "EXEMPT",
  FullyVaccinated = "FULLY_VACCINATED",
  PartiallyVaccinated = "PARTIALLY_VACCINATED",
  Unvaccinated = "UNVACCINATED",
  VaccinatedPlusBooster = "VACCINATED_PLUS_BOOSTER",
}

export type CovidVaccinationStats = {
  __typename?: "CovidVaccinationStats";
  fullyVaccinatedCount: Scalars["Int"];
  medicalExemptCount: Scalars["Int"];
  partiallyVaccinatedCount: Scalars["Int"];
  religiousExemptCount: Scalars["Int"];
  unvaccinatedCount: Scalars["Int"];
  vaccinatedPlusBoosterCount: Scalars["Int"];
};

export enum CovidVaccineReportingType {
  CarbonVerified = "CARBON_VERIFIED",
  SelfReported = "SELF_REPORTED",
  StateVerified = "STATE_VERIFIED",
}

export enum CovidVaccineType {
  Astrazeneca = "ASTRAZENECA",
  Janssen = "JANSSEN",
  Moderna = "MODERNA",
  Other = "OTHER",
  Pfizer = "PFIZER",
  Sinovac = "SINOVAC",
  Sputnik = "SPUTNIK",
  Unknown = "UNKNOWN",
}

/** Input to create organization members. */
export type CreateMembersInput = {
  groupId?: InputMaybe<Scalars["ID"]>;
  members: Array<MemberInput>;
};

/** Input used to create a rule */
export type CreateRuleInput = {
  /** A list of actions to be performed by the rule. */
  actions?: InputMaybe<Array<RuleActionInput>>;
  /** Specifies the specific conditions the rule with follow. */
  condition: ConditionInput;
  /** Text representing any description the rule creator would like to provide */
  description?: InputMaybe<Scalars["String"]>;
  /** The name of the rule. Max-length of 32 characters. */
  name: Scalars["String"];
  /**
   * Specifies the frequency at which the rule should run.
   * The field must be in the pattern of
   * /^Daily$/
   * /^Weekly\s([1-7])$/
   * /^Monthly\s(0?[1-9]|[12][0-8])$/
   */
  scheduleDuration?: InputMaybe<Scalars["String"]>;
  /** Text specify the team who created the rule */
  team?: InputMaybe<Scalars["String"]>;
};

/** The result of createRule mutation */
export type CreateRuleResponse = MutationResponse & {
  __typename?: "CreateRuleResponse";
  /**
   * When defined this list will contain error codes for this operation.
   * TODO: enumerate the possible error codes
   */
  codes?: Maybe<Array<Scalars["String"]>>;
  /** @deprecated based on @jackson dawkins rfc https://docs.google.com/document/d/1bYGuMtjfcTQYR7OZQfe_F6s_kU5av5JpxlvqcBCJWJE/edit#heading=h.p36yikvv4579 */
  message?: Maybe<Scalars["String"]>;
  /** The newly created rule if operation is successful */
  rule?: Maybe<Rule>;
  /** Overall success of the mutation. If there are any errors, this should be false */
  success: Scalars["Boolean"];
};

export type DailySymptomTrackingCheckin = {
  __typename?: "DailySymptomTrackingCheckin";
  checkinDate?: Maybe<Scalars["DateTime"]>;
  checkinTimezone?: Maybe<Scalars["String"]>;
  completed?: Maybe<Scalars["Boolean"]>;
  flag?: Maybe<DailySymptomTrackingFlag>;
};

export enum DailySymptomTrackingFlag {
  Exposed = "EXPOSED",
  MissedCheckin = "MISSED_CHECKIN",
  Symptomatic = "SYMPTOMATIC",
  SymptomaticAndExposed = "SYMPTOMATIC_AND_EXPOSED",
}

/**
 * This function is to enable translation from a DateRange into a concrete
 * RangeQuery.
 *
 * Definitions:
 * from - The starting point of the range. ISO8601 Format
 * to - The end point of the range. ISO8601 Format
 * lteDuration - The relative amount of time of which to end the range. ISO8601 Duration Format
 * gteDuration - The relative amount of time of which to start the range. ISO8601 Duration Format
 *
 * Duration Example 1:
 * As a caller I would like to specify a date range that older than 10 days.
 * Can be accomplished with DateRange(lteDuration: "P10D")
 *
 * Duration Example 2:
 * As a caller I would like to specify a date range that less than 10 days.
 * Can be accomplished with DateRange(gteDuration: "P10D")
 *
 * Valid combinations:
 *
 * Specifying only [lteDuration]
 * Specifying only [gteDuration]
 * Specifying only [to]
 * Specifying only [from]
 * Specifying [from, to]
 *
 * Invalid combinations:
 * Pairing either [from, to] with either [lteDuration, gteDuration]
 * Pairing either [lteDuration, gteDuration]
 */
export type DateRange = {
  __typename?: "DateRange";
  /** The starting point of the range. ISO8601 Format */
  from?: Maybe<Scalars["String"]>;
  /** The relative amount of time of which to start the range. ISO8601 Duration Format */
  gteDuration?: Maybe<Scalars["String"]>;
  /** The relative amount of time of which to end the range. ISO8601 Duration Format */
  lteDuration?: Maybe<Scalars["String"]>;
  /** The end point of the range. ISO8601 Format */
  to?: Maybe<Scalars["String"]>;
};

/**
 * This function is to enable translation from a DateRange into a concrete
 * RangeQuery.
 *
 * Definitions:
 * from - The starting point of the range. ISO8601 Format
 * to - The end point of the range. ISO8601 Format
 * lteDuration - The relative amount of time of which to end the range. ISO8601 Duration Format
 * gteDuration - The relative amount of time of which to start the range. ISO8601 Duration Format
 *
 * Duration Example 1:
 * As a caller I would like to specify a date range that older than 10 days.
 * Can be accomplished with DateRange(lteDuration: "P10D")
 *
 * Duration Example 2:
 * As a caller I would like to specify a date range that less than 10 days.
 * Can be accomplished with DateRange(gteDuration: "P10D")
 *
 * Valid combinations:
 *
 * Specifying only [lteDuration]
 * Specifying only [gteDuration]
 * Specifying only [to]
 * Specifying only [from]
 * Specifying [from, to]
 *
 * Invalid combinations:
 * Pairing either [from, to] with either [lteDuration, gteDuration]
 * Pairing either [lteDuration, gteDuration]
 */
export type DateRangeInput = {
  /** The starting point of the range. ISO8601 Format */
  from?: InputMaybe<Scalars["String"]>;
  /** The relative amount of time of which to start the range. ISO8601 Duration Format */
  gteDuration?: InputMaybe<Scalars["String"]>;
  /** The relative amount of time of which to end the range. ISO8601 Duration Format */
  lteDuration?: InputMaybe<Scalars["String"]>;
  /** The end point of the range. ISO8601 Format */
  to?: InputMaybe<Scalars["String"]>;
};

/** Input to delete organization members. */
export type DeleteMembersInput = {
  memberIds: Array<Scalars["ID"]>;
};

/** The result of deleteRule mutation */
export type DeleteRuleResponse = MutationResponse & {
  __typename?: "DeleteRuleResponse";
  /**
   * When defined this list will contain error codes for this operation.
   * TODO: enumerate the possible error codes
   */
  codes?: Maybe<Array<Scalars["String"]>>;
  /** @deprecated based on @jackson dawkins rfc https://docs.google.com/document/d/1bYGuMtjfcTQYR7OZQfe_F6s_kU5av5JpxlvqcBCJWJE/edit#heading=h.p36yikvv4579 */
  message?: Maybe<Scalars["String"]>;
  /** The rule id of the delete rule if operation is successful */
  ruleId?: Maybe<Scalars["ID"]>;
  /** Overall success of the mutation. If there are any errors, this should be false */
  success: Scalars["Boolean"];
};

export type DismissActionableInput = {
  actionableId: Scalars["ID"];
  patientId: Scalars["ID"];
};

export type DismissActionableResponse = MutationResponse & {
  __typename?: "DismissActionableResponse";
  actionable?: Maybe<Actionable>;
  message?: Maybe<Scalars["String"]>;
  patient?: Maybe<Patient>;
  success: Scalars["Boolean"];
};

/** A Document - e.g. a Provider might associate a Document with a Report or a Case. */
export type Document = Node & {
  __typename?: "Document";
  data?: Maybe<Scalars["String"]>;
  extension?: Maybe<Scalars["String"]>;
  filename?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
};

/** List of Documents and metadata. */
export type DocumentsConnection = Connection & {
  __typename?: "DocumentsConnection";
  nodes?: Maybe<Array<Maybe<Document>>>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type Dose = {
  __typename?: "Dose";
  manufacturer?: Maybe<CovidVaccineType>;
  reportTime?: Maybe<Scalars["DateTime"]>;
  reportingType?: Maybe<CovidVaccineReportingType>;
  vaccinationTime?: Maybe<Scalars["DateTime"]>;
};

export type EducationalMaterialActionable = Actionable &
  Node & {
    __typename?: "EducationalMaterialActionable";
    callToAction?: Maybe<Scalars["String"]>;
    dueDate?: Maybe<Scalars["DateTime"]>;
    id: Scalars["ID"];
    material?: Maybe<Material>;
    prompt?: Maybe<Scalars["String"]>;
    state?: Maybe<ActionableState>;
    stateTime?: Maybe<Scalars["DateTime"]>;
    subtext?: Maybe<Scalars["String"]>;
  };

export type Entity = {
  __typename?: "Entity";
  id: Scalars["ID"];
  type: Scalars["String"];
};

export type ExecutionInfo = {
  __typename?: "ExecutionInfo";
  executionStatus?: Maybe<ExecutionStatus>;
  lastExecutedAt?: Maybe<Scalars["DateTime"]>;
};

export enum ExecutionStatus {
  Completed = "COMPLETED",
  CompletedWithErrors = "COMPLETED_WITH_ERRORS",
  NotStarted = "NOT_STARTED",
  Running = "RUNNING",
}

export enum ExemptionType {
  Medical = "MEDICAL",
  Religious = "RELIGIOUS",
}

/** Response containing feature key and its corresponding value */
export type FeatureFlagAttributeInput = {
  /** The attribute key */
  key: Scalars["String"];
  /** The attribute value */
  value: Scalars["String"];
};

/** Response containing feature key and its corresponding value */
export type FeatureFlagResponse = {
  __typename?: "FeatureFlagResponse";
  /** The feature flag key */
  key: Scalars["String"];
  /** The current value of the feature flag */
  value?: Maybe<Scalars["FeatureFlagResponseBlob"]>;
};

/**
 * Filter used to search for bills that are
 * apart of a specific financial class or at the
 * subclass level.
 */
export type FinancialClassFilter = {
  __typename?: "FinancialClassFilter";
  /** Financial class */
  class: Scalars["String"];
  /** Financial subclasses */
  subclasses?: Maybe<Array<Scalars["String"]>>;
};

/**
 * Input filter to search for bills that are
 * apart of a specific financial class or at the
 * subclass level.
 */
export type FinancialClassFilterInput = {
  /** Financial class */
  class: Scalars["String"];
  /** Financial subclasses */
  subclasses?: InputMaybe<Array<Scalars["String"]>>;
};

export type FloatRange = {
  __typename?: "FloatRange";
  from?: Maybe<Scalars["Float"]>;
  to?: Maybe<Scalars["Float"]>;
};

export type FloatRangeInput = {
  from?: InputMaybe<Scalars["Float"]>;
  to?: InputMaybe<Scalars["Float"]>;
};

export type FollowUpAppointmentActionable = Actionable &
  Node & {
    __typename?: "FollowUpAppointmentActionable";
    apptReason?: Maybe<ApptReason>;
    callToAction?: Maybe<Scalars["String"]>;
    dueDate?: Maybe<Scalars["DateTime"]>;
    id: Scalars["ID"];
    inPersonFollowUp?: Maybe<InPersonFollowUp>;
    prompt?: Maybe<Scalars["String"]>;
    specialty?: Maybe<Specialty>;
    state?: Maybe<ActionableState>;
    stateTime?: Maybe<Scalars["DateTime"]>;
    subtext?: Maybe<Scalars["String"]>;
  };

export type GenericActionable = Actionable &
  Node & {
    __typename?: "GenericActionable";
    callToAction?: Maybe<Scalars["String"]>;
    dueDate?: Maybe<Scalars["DateTime"]>;
    id: Scalars["ID"];
    prompt?: Maybe<Scalars["String"]>;
    state?: Maybe<ActionableState>;
    stateTime?: Maybe<Scalars["DateTime"]>;
    subtext?: Maybe<Scalars["String"]>;
  };

export type Image = Node & {
  __typename?: "Image";
  data?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type InPersonFollowUp = Node & {
  __typename?: "InPersonFollowUp";
  id: Scalars["ID"];
};

export type IntRange = {
  __typename?: "IntRange";
  from?: Maybe<Scalars["Int"]>;
  to?: Maybe<Scalars["Int"]>;
};

export type IntRangeInput = {
  from?: InputMaybe<Scalars["Int"]>;
  to?: InputMaybe<Scalars["Int"]>;
};

/** Reasons why a job fail. This list originates from the monolith. */
export enum JobErrorCode {
  DataOutOfSync = "DATA_OUT_OF_SYNC",
  InternalServerError = "INTERNAL_SERVER_ERROR",
  /**
   * All other error types that do not correspond to the other enum values. Possibly a new value coming from the service
   * of origin.
   */
  Unknown = "UNKNOWN",
  WriteoffAttemptWhenNegativeOwedAmount = "WRITEOFF_ATTEMPT_WHEN_NEGATIVE_OWED_AMOUNT",
}

export type Location = {
  __typename?: "Location";
  id?: Maybe<Scalars["ID"]>;
  name?: Maybe<Scalars["String"]>;
  type?: Maybe<LocationType>;
};

/** Enum for all possible location types */
export enum LocationType {
  Clinic = "CLINIC",
  Enterprise = "ENTERPRISE",
  Imported = "IMPORTED",
  Mobile = "MOBILE",
  Popup = "POPUP",
  Travel = "TRAVEL",
  Vaccination = "VACCINATION",
  Virtual = "VIRTUAL",
}

/**
 * Filter to search for bills that are
 * associated with a particular location
 */
export type LocationsFilter = {
  __typename?: "LocationsFilter";
  /**
   * location ids that where provided as a filter are then
   * adapted to location shape to allow for resolving other aspects
   * like location name for instance
   */
  locations?: Maybe<Array<Location>>;
  /** list of location types */
  types?: Maybe<Array<LocationType>>;
};

/**
 * Input filter to search for bills that are
 * associated with a particular location
 */
export type LocationsFilterInput = {
  /** list of location ids */
  ids?: InputMaybe<Array<Scalars["String"]>>;
  /** list of location types */
  types?: InputMaybe<Array<LocationType>>;
};

export type MdEvent = Node & {
  __typename?: "MDEvent";
  id: Scalars["ID"];
};

export type Material = Node & {
  __typename?: "Material";
  id: Scalars["ID"];
};

/** Organization member input for mutations used to create members. */
export type MemberInput = {
  email?: InputMaybe<Scalars["String"]>;
  firstName: Scalars["String"];
  /** this is the org-controlled id */
  identification?: InputMaybe<Scalars["String"]>;
  lastName: Scalars["String"];
  phone?: InputMaybe<Scalars["String"]>;
};

/** Organization member mutations. Used to create, edit, invite, etc. */
export type Mutation = {
  __typename?: "Mutation";
  /**
   * Mutation to approve a rule
   *
   * Parameters:
   * ruleId - the rule id to be approved
   * runRuleUponApproval - optional field to specify whether the
   *     rule should run upon successful approval. The defaults value will be true.
   */
  approveRule?: Maybe<ApproveRuleResponse>;
  completeActionable?: Maybe<CompleteActionableResponse>;
  /** Mutation to create a rule */
  createRule?: Maybe<CreateRuleResponse>;
  /** Mutation to delete a rule */
  deleteRule?: Maybe<DeleteRuleResponse>;
  dismissActionable?: Maybe<DismissActionableResponse>;
  /** Used to create new members and add them to a group. Does _not_ include sending invitations */
  organizationAddMembers: OrganizationChangeMembersResponse;
  /**
   * Delete organization members by member id. This will trigger a soft
   * delete that sets the "deletedAt" and "deletedBy" fields rather than
   * completely removing the entry from the db table.
   */
  organizationDeleteMembers: OrganizationChangeMembersResponse;
  /** Change the group (worksite) for a given list of members. */
  organizationMembersChangeGroup: OrganizationChangeMembersResponse;
  /** Send invitations to a given list of members. */
  organizationMembersSendInvitation: OrganizationChangeMembersResponse;
  snoozeActionable?: Maybe<SnoozeActionableResponse>;
  /** Mutation to update a rule */
  updateRule?: Maybe<UpdateRuleResponse>;
};

/** Organization member mutations. Used to create, edit, invite, etc. */
export type MutationApproveRuleArgs = {
  ruleId: Scalars["ID"];
  runRuleUponApproval?: InputMaybe<Scalars["Boolean"]>;
};

/** Organization member mutations. Used to create, edit, invite, etc. */
export type MutationCompleteActionableArgs = {
  input?: InputMaybe<CompleteActionableInput>;
};

/** Organization member mutations. Used to create, edit, invite, etc. */
export type MutationCreateRuleArgs = {
  createRuleInput: CreateRuleInput;
};

/** Organization member mutations. Used to create, edit, invite, etc. */
export type MutationDeleteRuleArgs = {
  ruleId: Scalars["ID"];
};

/** Organization member mutations. Used to create, edit, invite, etc. */
export type MutationDismissActionableArgs = {
  input?: InputMaybe<DismissActionableInput>;
};

/** Organization member mutations. Used to create, edit, invite, etc. */
export type MutationOrganizationAddMembersArgs = {
  input: CreateMembersInput;
};

/** Organization member mutations. Used to create, edit, invite, etc. */
export type MutationOrganizationDeleteMembersArgs = {
  input: DeleteMembersInput;
};

/** Organization member mutations. Used to create, edit, invite, etc. */
export type MutationOrganizationMembersChangeGroupArgs = {
  groupId: Scalars["ID"];
  memberIds: Array<Scalars["ID"]>;
};

/** Organization member mutations. Used to create, edit, invite, etc. */
export type MutationOrganizationMembersSendInvitationArgs = {
  memberIds: Array<Scalars["ID"]>;
  message: Scalars["String"];
};

/** Organization member mutations. Used to create, edit, invite, etc. */
export type MutationSnoozeActionableArgs = {
  input?: InputMaybe<SnoozeActionableInput>;
};

/** Organization member mutations. Used to create, edit, invite, etc. */
export type MutationUpdateRuleArgs = {
  updateRuleInput: UpdateRuleInput;
};

/**
 * This interface can be used on the result of an _entire_ mutation or on a _piece_ of the
 * mutation, like in the case of updating multiple users, the result of updating each user
 *
 * TODO: Add additional docs
 */
export type MutationResponse = {
  message?: Maybe<Scalars["String"]>;
  /** Overall success of the mutation. If there are any errors, this should be false */
  success: Scalars["Boolean"];
};

export type Node = {
  id: Scalars["ID"];
};

/** Operators that allow us to join conditions */
export enum Operator {
  And = "AND",
  Nand = "NAND",
  Nor = "NOR",
  Or = "OR",
}

export type Organization = Node & {
  __typename?: "Organization";
  /** @deprecated Internal ids should no longer be used, see Global ID RFC [https://mdcollab.atlassian.net/wiki/spaces/engineering/pages/976978209/2021-08-02+-+Graph+Object+Identity+RFC] */
  _internalId: Scalars["ID"];
  availableReports?: Maybe<Array<Maybe<OrganizationReport>>>;
  coveredServices?: Maybe<Scalars["String"]>;
  covidHomeTestKitOrders?: Maybe<CovidHomeTestKitOrdersConnection>;
  covidTestingStats?: Maybe<CovidTestingStats>;
  covidTests?: Maybe<CovidTestsConnection>;
  covidVaccinationStats?: Maybe<CovidVaccinationStats>;
  groups?: Maybe<OrganizationGroupsConnection>;
  id: Scalars["ID"];
  members?: Maybe<OrganizationMembersConnection>;
  name?: Maybe<Scalars["String"]>;
  typ?: Maybe<OrganizationType>;
};

export type OrganizationCovidHomeTestKitOrdersArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  groupId?: InputMaybe<Scalars["ID"]>;
  last?: InputMaybe<Scalars["Int"]>;
  query?: InputMaybe<Scalars["String"]>;
};

export type OrganizationCovidTestsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  groupId?: InputMaybe<Scalars["ID"]>;
  last?: InputMaybe<Scalars["Int"]>;
  lastXDays?: InputMaybe<Scalars["Int"]>;
  query?: InputMaybe<Scalars["String"]>;
  testResultsInput?: InputMaybe<TestResultsInput>;
};

export type OrganizationGroupsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type OrganizationMembersArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  groupId?: InputMaybe<Scalars["ID"]>;
  last?: InputMaybe<Scalars["Int"]>;
  query?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<OrganizationMemberStatus>;
};

export type OrganizationAdminUser = Node &
  User & {
    __typename?: "OrganizationAdminUser";
    email: Scalars["String"];
    firstName?: Maybe<Scalars["String"]>;
    id: Scalars["ID"];
    lastName?: Maybe<Scalars["String"]>;
    member?: Maybe<OrganizationMember>;
    organization: Organization;
  };

export type OrganizationAdminUserMemberArgs = {
  id: Scalars["ID"];
};

/** This response type can be used when editing/creating/moving/deleting bulk sets of members */
export type OrganizationChangeMembersResponse = MutationResponse & {
  __typename?: "OrganizationChangeMembersResponse";
  members?: Maybe<Array<Maybe<OrganizationMemberChangeResponse>>>;
  message?: Maybe<Scalars["String"]>;
  success: Scalars["Boolean"];
};

export type OrganizationConnection = Connection & {
  __typename?: "OrganizationConnection";
  nodes?: Maybe<Array<Maybe<Organization>>>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type OrganizationGroup = Node & {
  __typename?: "OrganizationGroup";
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type OrganizationGroupsConnection = Connection & {
  __typename?: "OrganizationGroupsConnection";
  nodes?: Maybe<Array<Maybe<OrganizationGroup>>>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type OrganizationMember = Node & {
  __typename?: "OrganizationMember";
  contact?: Maybe<Scalars["String"]>;
  /**
   * This field doesn't have pagination because we think only last 7 to 30 check-ins
   * are going to be relevant and needed.
   * Pagination should be supported if this turns out to be wrong or changes in the future.
   */
  dailySymptomTrackingCheckins?: Maybe<Array<Maybe<DailySymptomTrackingCheckin>>>;
  email?: Maybe<Scalars["String"]>;
  enrollmentInfo?: Maybe<OrganizationMemberEnrollmentInfo>;
  firstName?: Maybe<Scalars["String"]>;
  fullName?: Maybe<Scalars["String"]>;
  group?: Maybe<OrganizationGroup>;
  id: Scalars["ID"];
  identification?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  organization?: Maybe<Organization>;
  patient?: Maybe<Patient>;
  phone?: Maybe<Scalars["String"]>;
  status?: Maybe<OrganizationMemberStatus>;
};

export type OrganizationMemberDailySymptomTrackingCheckinsArgs = {
  last?: InputMaybe<Scalars["Int"]>;
};

/**
 * This type can represent changes to any member that requires a refetch of the member
 *
 * TODO: Add additional docs
 */
export type OrganizationMemberChangeResponse = MutationResponse & {
  __typename?: "OrganizationMemberChangeResponse";
  member?: Maybe<OrganizationMember>;
  message?: Maybe<Scalars["String"]>;
  success: Scalars["Boolean"];
};

export type OrganizationMemberEnrollmentInfo = {
  __typename?: "OrganizationMemberEnrollmentInfo";
  invitationCode?: Maybe<Scalars["String"]>;
  inviteDate?: Maybe<Scalars["DateTime"]>;
  latestInvitationDate?: Maybe<Scalars["DateTime"]>;
  registrationDate?: Maybe<Scalars["DateTime"]>;
};

export enum OrganizationMemberStatus {
  Created = "CREATED",
  Enrolled = "ENROLLED",
  Errored = "ERRORED",
  Invited = "INVITED",
}

export type OrganizationMembersConnection = Connection & {
  __typename?: "OrganizationMembersConnection";
  nodes?: Maybe<Array<Maybe<OrganizationMember>>>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type OrganizationReport = {
  __typename?: "OrganizationReport";
  displayName: Scalars["String"];
  slug: Scalars["String"];
  typ: Scalars["String"];
};

export enum OrganizationType {
  Enterprise = "ENTERPRISE",
  OccHealth = "OCC_HEALTH",
  Program = "PROGRAM",
  Standard = "STANDARD",
  University = "UNIVERSITY",
}

export type PageCursor = {
  __typename?: "PageCursor";
  cursor: Scalars["String"];
  pageNumber: Scalars["Int"];
};

export type PageInfo = {
  __typename?: "PageInfo";
  currentPageNumber: Scalars["Int"];
  endCursor: Scalars["String"];
  firstPage?: Maybe<PageCursor>;
  hasNextPage: Scalars["Boolean"];
  hasPreviousPage: Scalars["Boolean"];
  lastPage?: Maybe<PageCursor>;
  nearbyPages: Array<PageCursor>;
  startCursor: Scalars["String"];
};

export type Patient = Node & {
  __typename?: "Patient";
  actionables?: Maybe<ActionablesConnection>;
  appointmentsBySpecialties?: Maybe<AppointmentsConnection>;
  covidTests?: Maybe<Array<Maybe<CovidLabResult>>>;
  covidVaccination?: Maybe<VaccinationConsentBoundary>;
  documents?: Maybe<Array<Maybe<Document>>>;
  firstName?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  lastName?: Maybe<Scalars["String"]>;
  mostRecentHomeTestKit?: Maybe<CovidHomeTestKitOrder>;
  /** @deprecated We will replace this with a call to covidTests with last = 1. */
  mostRecentLabResult?: Maybe<CovidLabResult>;
  mrn?: Maybe<Scalars["Int"]>;
};

export type PatientActionablesArgs = {
  after?: InputMaybe<Scalars["Int"]>;
  before?: InputMaybe<Scalars["Int"]>;
  filters?: InputMaybe<ActionableFilters>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["String"]>;
  state?: InputMaybe<ActionableState>;
};

export type PatientAppointmentsBySpecialtiesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filters: AppointmentsBySpecialtiesFilterInput;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type PatientCovidTestsArgs = {
  last?: InputMaybe<Scalars["Int"]>;
  lastXDays?: InputMaybe<Scalars["Int"]>;
};

export type PatientDocumentsArgs = {
  ids: Array<Scalars["ID"]>;
};

export type PatientUser = Node & {
  __typename?: "PatientUser";
  id: Scalars["ID"];
  patient?: Maybe<Patient>;
};

export type PatientUserPatientArgs = {
  id: Scalars["ID"];
};

/**
 * An actionable that prompts the patient to Pay their Bill and
 * takes them to the Bill Detail Screen
 */
export type PayBillActionable = Actionable &
  Node & {
    __typename?: "PayBillActionable";
    bill?: Maybe<Bill>;
    callToAction?: Maybe<Scalars["String"]>;
    dueDate?: Maybe<Scalars["DateTime"]>;
    id: Scalars["ID"];
    prompt?: Maybe<Scalars["String"]>;
    state?: Maybe<ActionableState>;
    stateTime?: Maybe<Scalars["DateTime"]>;
    subtext?: Maybe<Scalars["String"]>;
  };

export type Payer = {
  __typename?: "Payer";
  carrier?: Maybe<Carrier>;
  id?: Maybe<Scalars["ID"]>;
  name?: Maybe<Scalars["String"]>;
};

export type Payers = {
  __typename?: "Payers";
  additionalPayers?: Maybe<Array<Maybe<Payer>>>;
  primary?: Maybe<Payer>;
};

export type PayersFilter = {
  __typename?: "PayersFilter";
  carriers?: Maybe<Array<Carrier>>;
  ids?: Maybe<Array<Scalars["ID"]>>;
  names?: Maybe<Array<Scalars["String"]>>;
};

export type PayersFilterInput = {
  carrierIds?: InputMaybe<Array<Scalars["ID"]>>;
  ids?: InputMaybe<Array<Scalars["ID"]>>;
  names?: InputMaybe<Array<Scalars["String"]>>;
};

/**
 * A Practice is a corporate entity with its own data.
 * Carbon Health itself is a Practice, but our software and servers also
 * power other healthcare chains with their own doctors, patients, etc.
 */
export type Practice = {
  __typename?: "Practice";
  appointmentReasons?: Maybe<PracticeAppointmentReasonConnection>;
  id?: Maybe<Scalars["ID"]>;
  slug?: Maybe<Scalars["String"]>;
};

/**
 * A Practice is a corporate entity with its own data.
 * Carbon Health itself is a Practice, but our software and servers also
 * power other healthcare chains with their own doctors, patients, etc.
 */
export type PracticeAppointmentReasonsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
};

/**
 * A reason that a patient may want to schedule an appointment. Each Practice can choose what
 * the valid reasons for appointments are.
 */
export type PracticeAppointmentReason = Node & {
  __typename?: "PracticeAppointmentReason";
  appointmentReason?: Maybe<AppointmentReason>;
  /**
   * The amount of time that a Practice expects to spend on an appointment for this AppointmentReason,
   * in minutes, assuming the patient has been here before.
   *
   * This is a fallback value for the Practice. It can be overridden by existingPatientDurationMinutes for a particular
   * Specialty on the linked specialties.
   */
  existingPatientDurationMinutes?: Maybe<Scalars["Int"]>;
  id: Scalars["ID"];
  /**
   * The amount of time that a Practice expects to spend on an appointment for this AppointmentReason,
   * in minutes, assuming the patient has been here before.
   *
   * This is a fallback value for the Practice. It can be overridden by existingPatientDurationMinutes for a particular
   * Specialty on the linked specialties.
   */
  newPatientDurationMinutes?: Maybe<Scalars["Int"]>;
  /**
   * A human-readable, URL-friendly string which uniquely identifies this appointment reason for a particular
   * Practice. For example, the Carbon practice uses these to power https://carbonhealth.com/get-care/{slug}
   */
  slug?: Maybe<Scalars["String"]>;
  /** A link to the metadata that requires an (AppointmentReason, Practice, Specialty) tuple. */
  specialties?: Maybe<Array<Maybe<PracticeAppointmentReasonSpecialty>>>;
};

/** A paginated list of AppointmentReason objects. */
export type PracticeAppointmentReasonConnection = Connection & {
  __typename?: "PracticeAppointmentReasonConnection";
  nodes?: Maybe<Array<Maybe<PracticeAppointmentReason>>>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars["Int"]>;
};

/**
 * Metadata associated with an (AppointmentReason, Practice, Specialty) tuple.
 *
 * If present, the "existingPatientDurationMinutes" and "newPatientDurationMinutes" should
 * override those from the associated AppointmentReasonPractice.
 */
export type PracticeAppointmentReasonSpecialty = Node & {
  __typename?: "PracticeAppointmentReasonSpecialty";
  /**
   * The amount of time that a Practice expects this Specialty to spend on an appointment for this AppointmentReason,
   * in minutes, assuming the patient has been here before.
   *
   * If undefined, use existingPatientDurationMinutes from the AppointmentReasonPractice that this object belongs to.
   */
  existingPatientDurationMinutes?: Maybe<Scalars["Int"]>;
  id: Scalars["ID"];
  /**
   * The amount of time that a Practice expects this Specialty to spend on an appointment for this AppointmentReason,
   * in minutes, assuming that this is a new patient.
   *
   * If undefined, use newPatientDurationMinutes from the AppointmentReasonPractice that this object belongs to.
   */
  newPatientDurationMinutes?: Maybe<Scalars["Int"]>;
  specialty?: Maybe<Specialty>;
};

/**
 * Input filter to search for bills that are
 * associated with a particular practice
 */
export type PracticesFilterInput = {
  /** list of practice slugs */
  slugs?: InputMaybe<Array<Scalars["String"]>>;
};

export type Provider = {
  __typename?: "Provider";
  /** Provider first name */
  firstName?: Maybe<Scalars["String"]>;
  /** Provider last name */
  lastName?: Maybe<Scalars["String"]>;
  /**
   * provider npi (National Provider Identifier)
   * Ref: https://npiregistry.cms.hhs.gov/
   */
  npi?: Maybe<Scalars["String"]>;
};

export type ProviderUser = Node & {
  __typename?: "ProviderUser";
  firstName?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  lastName?: Maybe<Scalars["String"]>;
  organizationSearch?: Maybe<OrganizationConnection>;
  organizationsByIds?: Maybe<Array<Maybe<Organization>>>;
};

export type ProviderUserOrganizationSearchArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  query?: InputMaybe<Scalars["String"]>;
};

export type ProviderUserOrganizationsByIdsArgs = {
  ids: Array<Scalars["ID"]>;
};

/**
 * Input filter to search for bills based on specifics
 * about the Provider associated with a bill
 */
export type ProvidersFilterInput = {
  /**
   * List of providers npi (National Provider Identifier)
   * Ref: https://npiregistry.cms.hhs.gov/
   */
  npis?: InputMaybe<Array<Scalars["String"]>>;
};

export type Query = {
  __typename?: "Query";
  /** Provides a way to query for a feature flag value */
  featureFlags?: Maybe<Array<Maybe<FeatureFlagResponse>>>;
  me?: Maybe<UserBoundary>;
  /** Get a practice by its ID. */
  practice?: Maybe<Practice>;
  trails?: Maybe<Array<Maybe<Trail>>>;
};

export type QueryFeatureFlagsArgs = {
  anonymous?: InputMaybe<Scalars["Boolean"]>;
  attributes?: InputMaybe<Array<FeatureFlagAttributeInput>>;
  keys: Array<Scalars["String"]>;
};

export type QueryPracticeArgs = {
  id: Scalars["ID"];
};

export type QueryTrailsArgs = {
  filter?: InputMaybe<TrailsFilter>;
  id: Scalars["ID"];
  type: Scalars["String"];
};

export type ReferralActionable = Actionable &
  Node & {
    __typename?: "ReferralActionable";
    callToAction?: Maybe<Scalars["String"]>;
    dueDate?: Maybe<Scalars["DateTime"]>;
    id: Scalars["ID"];
    prompt?: Maybe<Scalars["String"]>;
    referralSuggestion?: Maybe<ReferralSuggestion>;
    specialty?: Maybe<Specialty>;
    state?: Maybe<ActionableState>;
    stateTime?: Maybe<Scalars["DateTime"]>;
    subtext?: Maybe<Scalars["String"]>;
    treatmentPlan?: Maybe<TreatmentPlan>;
  };

export type ReferralSuggestion = Node & {
  __typename?: "ReferralSuggestion";
  id: Scalars["ID"];
};

export enum ResponsiblePartyType {
  Default = "Default",
  Insurance = "Insurance",
  Organization = "Organization",
  Patient = "Patient",
}

export enum ReviewStatus {
  Approved = "APPROVED",
  AwaitingApproval = "AWAITING_APPROVAL",
  None = "NONE",
}

export type Rule = Node & {
  __typename?: "Rule";
  /** List actions to be performed when the rule is ran. */
  actions?: Maybe<Array<Maybe<RuleAction>>>;
  /** The author of the rule */
  author?: Maybe<BillingUser>;
  /** Provides access to see preview of what bills the rule would operate upon */
  billsPreview?: Maybe<BillsConnection>;
  condition?: Maybe<Condition>;
  /** The date the rule was created */
  createdAt?: Maybe<Scalars["DateTime"]>;
  /** Description Text provided during creation of the rule */
  description?: Maybe<Scalars["String"]>;
  execution?: Maybe<RuleExecution>;
  executionInfo?: Maybe<ExecutionInfo>;
  executions?: Maybe<RuleExecutionConnection>;
  /** Id of the rule */
  id: Scalars["ID"];
  /** The name of the rule */
  name?: Maybe<Scalars["String"]>;
  /** The current review status of the rule. Approved or Awaiting */
  reviewStatus?: Maybe<ReviewStatus>;
  /** The approver of the rule */
  reviewer?: Maybe<BillingUser>;
  /**
   * Specifies the frequency at which the rule should run.
   * The field is in the pattern of
   * /^Daily$/
   * /^Weekly\s([1-7])$/
   * /^Monthly\s(0?[1-9]|[12][0-8])$/
   */
  scheduleDuration?: Maybe<Scalars["String"]>;
  status?: Maybe<RuleStatus>;
  /** Team text provided during creation of the rule */
  team?: Maybe<Scalars["String"]>;
  /** The type of the rule */
  type?: Maybe<RuleType>;
};

export type RuleBillsPreviewArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type RuleExecutionArgs = {
  id: Scalars["ID"];
};

export type RuleExecutionsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type RuleAction = {
  __typename?: "RuleAction";
  changePayerParameters?: Maybe<ChangePayerParameters>;
  changeStatusParameters?: Maybe<ChangeStatusParameters>;
  name: RuleActionName;
  order: Scalars["Int"];
  writeOffParameters?: Maybe<WriteOffParameters>;
};

export type RuleActionInput = {
  changePayerParameters?: InputMaybe<ChangePayerParametersInput>;
  changeStatusParameters?: InputMaybe<ChangeStatusParametersInput>;
  name: RuleActionName;
  order: Scalars["Int"];
  writeOffParameters?: InputMaybe<WriteOffParametersInput>;
};

export enum RuleActionName {
  ChangePayer = "CHANGE_PAYER",
  ChangeStatus = "CHANGE_STATUS",
  CorrectClaim = "CORRECT_CLAIM",
  NoopDataOutOfSync = "NOOP_DATA_OUT_OF_SYNC",
  NoopFail = "NOOP_FAIL",
  NoopSuccess = "NOOP_SUCCESS",
  RebillClaim = "REBILL_CLAIM",
  RefundViaStripe = "REFUND_VIA_STRIPE",
  VoidClaim = "VOID_CLAIM",
  WriteOff = "WRITE_OFF",
}

export type RuleExecution = Node & {
  __typename?: "RuleExecution";
  billExecutionJobs?: Maybe<BillExecutionJobsConnection>;
  errorCount?: Maybe<Scalars["Int"]>;
  id: Scalars["ID"];
  startedAt?: Maybe<Scalars["DateTime"]>;
  successCount?: Maybe<Scalars["Int"]>;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type RuleExecutionBillExecutionJobsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  status?: InputMaybe<BillExecutionJobStatus>;
};

export type RuleExecutionConnection = Connection & {
  __typename?: "RuleExecutionConnection";
  nodes?: Maybe<Array<Maybe<RuleExecution>>>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars["Int"]>;
};

export enum RuleStatus {
  Active = "ACTIVE",
  Approved = "APPROVED",
  Archived = "ARCHIVED",
  /** Only applied to scheduled rule type */
  Disabled = "DISABLED",
}

export enum RuleType {
  OneTime = "ONE_TIME",
  Scheduled = "SCHEDULED",
}

export type RulesConnection = Connection & {
  __typename?: "RulesConnection";
  nodes?: Maybe<Array<Maybe<Rule>>>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type RxFillActionable = Actionable &
  Node & {
    __typename?: "RxFillActionable";
    callToAction?: Maybe<Scalars["String"]>;
    dueDate?: Maybe<Scalars["DateTime"]>;
    event?: Maybe<MdEvent>;
    id: Scalars["ID"];
    prompt?: Maybe<Scalars["String"]>;
    state?: Maybe<ActionableState>;
    stateTime?: Maybe<Scalars["DateTime"]>;
    subtext?: Maybe<Scalars["String"]>;
    treatmentPlan?: Maybe<TreatmentPlan>;
  };

export type SnoozeActionableInput = {
  actionableId: Scalars["ID"];
  patientId: Scalars["ID"];
};

export type SnoozeActionableResponse = MutationResponse & {
  __typename?: "SnoozeActionableResponse";
  actionable?: Maybe<Actionable>;
  message?: Maybe<Scalars["String"]>;
  patient?: Maybe<Patient>;
  success: Scalars["Boolean"];
};

/** Specialties are things like Urgent Care, Virtual Urgent Care, Primary Care, etc. */
export type Specialty = {
  __typename?: "Specialty";
  id?: Maybe<Scalars["ID"]>;
  name?: Maybe<Scalars["String"]>;
};

export enum TestResult {
  AwaitingResults = "AWAITING_RESULTS",
  Inconclusive = "INCONCLUSIVE",
  Negative = "NEGATIVE",
  Positive = "POSITIVE",
  Unknown = "UNKNOWN",
}

export type TestResultsInput = {
  testResults?: InputMaybe<Array<TestResult>>;
};

export enum TestTimeType {
  CollectionTime = "COLLECTION_TIME",
  ResultReceivedTime = "RESULT_RECEIVED_TIME",
}

export type Trail = Node & {
  __typename?: "Trail";
  /** Type of action performed on the entity */
  action?: Maybe<Action>;
  /** Actor on the entity */
  actor?: Maybe<Actor>;
  /**
   * Changes represent an arbitrary json string of property changes
   * of the given entity. The size and the content is arbitrary and
   * depends on the properties of the entity
   */
  changes?: Maybe<Scalars["JSON"]>;
  /** Entity to which the trail belongs */
  entity?: Maybe<Entity>;
  /** ID of the trail */
  id: Scalars["ID"];
  /** Time at which the event happened */
  timeOfEvent?: Maybe<Scalars["DateTime"]>;
};

export type TrailsFilter = {
  entityTypes?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  from?: InputMaybe<Scalars["DateTime"]>;
  hierarchy?: InputMaybe<Scalars["Boolean"]>;
  to?: InputMaybe<Scalars["DateTime"]>;
};

export type TreatmentPlan = Node & {
  __typename?: "TreatmentPlan";
  id: Scalars["ID"];
};

/** Input used to update a rule */
export type UpdateRuleInput = {
  /** A list of actions to be performed by the rule. */
  actions?: InputMaybe<Array<RuleActionInput>>;
  /** Specifies the specific conditions the rule with follow. */
  condition?: InputMaybe<ConditionInput>;
  /** Defines whether the rule should be enabled */
  enabled?: InputMaybe<Scalars["Boolean"]>;
  /** The name of the rule. Max-length of 32 characters. */
  name?: InputMaybe<Scalars["String"]>;
  /** The id of the rule being updated */
  ruleId: Scalars["ID"];
  /**
   * Specifies the frequency at which the rule should run.
   * The field must be in the pattern of
   * /^Daily$/
   * /^Weekly\s([1-7])$/
   * /^Monthly\s(0?[1-9]|[12][0-8])$/
   */
  scheduleDuration?: InputMaybe<Scalars["String"]>;
};

/** The result of updateRule mutation */
export type UpdateRuleResponse = MutationResponse & {
  __typename?: "UpdateRuleResponse";
  /**
   * When defined this list will contain error codes for this operation.
   * TODO: enumerate the possible error codes
   */
  codes?: Maybe<Array<Scalars["String"]>>;
  /** @deprecated based on @jackson dawkins rfc https://docs.google.com/document/d/1bYGuMtjfcTQYR7OZQfe_F6s_kU5av5JpxlvqcBCJWJE/edit#heading=h.p36yikvv4579 */
  message?: Maybe<Scalars["String"]>;
  /** The updated rule if operation is successful */
  rule?: Maybe<Rule>;
  /** Overall success of the mutation. If there are any errors, this should be false */
  success: Scalars["Boolean"];
};

export type User = {
  email: Scalars["String"];
  id: Scalars["ID"];
};

export type UserBoundary =
  | AuthenticationError
  | BillingUser
  | OrganizationAdminUser
  | PatientUser
  | ProviderUser;

export type VaccinationConsentBoundary = ConsentError | CovidVaccination;

export type WriteOffParameters = {
  __typename?: "WriteOffParameters";
  reason?: Maybe<Scalars["String"]>;
  writeOffReasonId: Scalars["ID"];
};

export type WriteOffParametersInput = {
  writeOffReasonId: Scalars["ID"];
};
