import {Maybe} from "@ch/foundations/types";
import {View, StyleProp, ViewStyle, ColorValue} from "react-native";
import {tailwind} from "@ch/foundations/styles";
import {useHover} from "@ch/foundations/hooks/useHover";
import {LegacyRef} from "react";
type NavItemProps = {
  expanded: boolean;
  onPress?: () => void;
  style?: StyleProp<ViewStyle>;
  children: React.ReactNode;
  activeColor?: Maybe<ColorValue>;
  hoverColor?: Maybe<ColorValue>;
  height: number;
  active?: boolean;
};

/**
 * This component is just a container for items in the navbar on the left. The
 * container can be shrinked (with expanded: false) to form a circle. The caller
 * should remove any text or anything else that'd overflow the cirlce in that
 * case.
 *
 * It's left flexible, so it can be used in a number of ways, like supporting
 * the search box, nav links, as well as the setting icon at the bottom
 *
 * Example usage:
 *  <NavItem expanded={expanded} style={tailwind("h-12")}>
 *    <Icon name="person" size={24} clearBackground />
 *    {expanded && <Caption style={tailwind("text-gray-800")}>My Organization</Caption>}
 *  </NavItem>
 */
export default function NavItem({
  expanded,
  style,
  children,
  activeColor,
  hoverColor,
  active,
  height,
}: NavItemProps) {
  const [ref, hovered] = useHover();
  const backgroundColor =
    active && activeColor ? activeColor : hovered && hoverColor ? hoverColor : undefined;

  return (
    /* 
      container to solve width - 12px margin issue: 
      We want the box to grow/shrink with what ever container it's in,
      in effect, 100% - 12px margin on left and right
    */
    <View
      ref={ref as LegacyRef<View>}
      style={[tailwind("w-full items-center flex-row justify-center"), style, {height}]}
    >
      <View
        style={[
          // base styles -- for all states
          tailwind("flex flex-row  items-center rounded-full h-full overflow-hidden"),
          // styles dependent on whether or not the component is expanded
          expanded ? tailwind("flex-1 p-3 justify-start mx-3") : tailwind("justify-center p-3"),
          // styles dependent on component props
          // shrink to match height to make perfect circle when collapsed
          expanded && {width: height},
        ]}
      >
        {children}

        {/* background color mask, locked to max size behind the content */}
        <View
          style={[
            tailwind("absolute left-0 right-0 top-0 bottom-0"),
            {
              backgroundColor,
              zIndex: -1,
            },
          ]}
        />
      </View>
    </View>
  );
}
