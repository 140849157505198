/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
// https://github.com/sandiiarov/use-deep-compare/blob/master/src/useDeepCompareMemo.ts

import React from "react";
import {shouldUseBaseHook, useDeepCompareMemoize} from "./useDeepCompareMemoize";

/**
 * `useDeepCompareMemo` will only recompute the memoized value when one of the
 * `deps` has changed.
 *
 * Usage note: if `deps` is empty or all primitives, then this module will return
 * React.useMemo directly and not deep compare deps.
 *
 */
function _useDeepCompareMemo<T>(factory: () => T, dependencies?: React.DependencyList): T {
  return React.useMemo(factory, useDeepCompareMemoize(dependencies));
}

export default <T>(factory: () => T, dependencies?: React.DependencyList): T => {
  return shouldUseBaseHook(dependencies)
    ? React.useMemo(factory, dependencies)
    : _useDeepCompareMemo(factory, dependencies);
};
