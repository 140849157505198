import {useEffect, useRef} from "react";

// Intended to be used to track previous state
export function usePrevious<T>(value: T) {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
