import {useEffect, useRef} from "react";
import {Animated, View, TouchableOpacity} from "react-native";
import {Icon, CarbonLogoText, Caption} from "@ch/foundations/ui";
import {Link} from "../../lib/Link";
import {tailwind} from "@ch/foundations/styles";
import NavItem from "./NavItem";
import colors from "@ch/foundations/ui/colors.json";
import {useRouter} from "next/router";
import {usePrevious} from "@ch/foundations/hooks/usePrevious";
import {useAuth} from "@ch/foundations/hooks/useAuth";
import {OrganizationType} from "../../types/__generated__/graphql";
import OrgTypeBoundary from "../OrgTypeBoundary";

export const EXPANDED_WIDTH = 224;
export const COLLAPSED_WIDTH = 96;

type CollapsibleNavProps = {
  expanded: boolean;
  onExpandToggle: (expanded: boolean) => void;
};

export default function CollapsibleNav({expanded, onExpandToggle}: CollapsibleNavProps) {
  const router = useRouter();
  const currentRoute = router && router.asPath;
  const {signOut} = useAuth();
  const prevExpanded = usePrevious(expanded);
  const widthAnim = useRef(new Animated.Value(expanded ? EXPANDED_WIDTH : COLLAPSED_WIDTH)).current;

  useEffect(() => {
    if (prevExpanded === expanded) {
      // Only run animation when expanded changes
      return;
    }
    if (expanded) {
      // Animate the expand
      Animated.timing(widthAnim, {
        toValue: EXPANDED_WIDTH,
        duration: 260,
        useNativeDriver: false,
      }).start();
    } else {
      // Animate the collapse
      Animated.timing(widthAnim, {
        toValue: COLLAPSED_WIDTH,
        duration: 260,
        useNativeDriver: false,
      }).start();
    }
  }, [expanded, prevExpanded, widthAnim]);

  return (
    <Animated.View
      style={[
        tailwind("flex flex-col items-center bg-gray-50 h-full border-r border-gray-100"),
        {width: widthAnim},
      ]}
      accessibilityRole="navigation"
    >
      {/* Logo and collapse button row */}
      <View
        style={tailwind(
          `h-12 m-8 mb-6 items-center justify-around flex-row ${expanded ? "w-48" : ""}`,
        )}
      >
        {!expanded && <View style={{width: 10, marginRight: 8}} />}
        <Link
          accessibilityLabel="Go to the Carbon Enterprise Dashboard Home"
          href="/"
          style={tailwind("flex-row items-center justify-center")}
        >
          <Icon name="carbon" size={24} />
          {expanded && (
            <View style={tailwind("ml-3")}>
              <CarbonLogoText height={12} />
            </View>
          )}
        </Link>
        <TouchableOpacity
          testID="nav-toggle"
          onPress={() => onExpandToggle(!expanded)}
          style={{
            marginLeft: 8,
            transform: [{rotate: expanded ? "0deg" : "180deg"}],
          }}
        >
          <Icon name="left-arrow" size={10} />
        </TouchableOpacity>
      </View>

      {/* Search box */}
      {/* <TouchableOpacity onPress={openSearch} style={tailwind("w-full mb-10")}>
        <NavItem expanded={expanded} height={40} activeColor={colors.gray[100]}>
          <Icon name="search" size={14} color={colors.gray[200]} />
          {expanded && (
            <Caption level={2} style={tailwind("text-gray-800 ml-2")}>
              Search
            </Caption>
          )}
        </NavItem>
      </TouchableOpacity> */}

      {/* nav links */}
      <TouchableOpacity onPress={() => router.push("/")} style={tailwind("w-full mb-2")}>
        <NavItem
          expanded={expanded}
          active={currentRoute === "/"}
          activeColor={colors.mint.lighter}
          hoverColor={colors.gray[100]}
          height={48}
        >
          <View style={tailwind("h-6 w-6")}>
            <Icon name="dashboard" size={24} clearBackground />
          </View>
          {expanded && <Caption style={tailwind("text-gray-800 ml-2")}>Dashboard</Caption>}
        </NavItem>
      </TouchableOpacity>

      <TouchableOpacity
        onPress={() => router.push("/organization")}
        style={tailwind("w-full mb-2")}
      >
        <NavItem
          expanded={expanded}
          height={48}
          active={currentRoute === "/organization"}
          activeColor={colors.mint.lighter}
          hoverColor={colors.gray[100]}
        >
          <View style={tailwind("h-6 w-6")}>
            <Icon name="organization" size={24} clearBackground />
          </View>
          {expanded && <Caption style={tailwind("text-gray-800 ml-2")}>My Organization</Caption>}
        </NavItem>
      </TouchableOpacity>

      <OrgTypeBoundary
        showFor={[
          OrganizationType.Enterprise,
          OrganizationType.University,
          OrganizationType.Program,
          null,
        ]}
      >
        <TouchableOpacity onPress={() => router.push("/reports")} style={tailwind("w-full mb-2")}>
          <NavItem
            expanded={expanded}
            height={48}
            active={!!currentRoute && currentRoute.includes("/reports")}
            activeColor={colors.mint.lighter}
            hoverColor={colors.gray[100]}
          >
            <View style={tailwind("h-6 w-6")}>
              <Icon name="report" size={24} clearBackground />
            </View>
            {expanded && <Caption style={tailwind("text-gray-800 ml-2")}>Reports</Caption>}
          </NavItem>
        </TouchableOpacity>
      </OrgTypeBoundary>

      {/* Sign Out */}
      <TouchableOpacity
        testID="signOutNavButton"
        onPress={signOut}
        style={tailwind("w-full bottom-0 absolute mb-2")}
      >
        <NavItem expanded={expanded} height={48}>
          <View style={tailwind("h-6 w-6")}>
            <Icon name="sign-out" size={24} clearBackground />
          </View>
          {expanded && <Caption style={tailwind("text-gray-800 ml-2")}>Sign Out</Caption>}
        </NavItem>
      </TouchableOpacity>

      {/* Settings Icon at the bottom */}
      {/* <TouchableOpacity
        onPress={() => router.push("/settings")}
        style={tailwind("w-full bottom-0 absolute mb-2")}
      >
        <NavItem
          expanded={expanded}
          height={48}
          activeColor={currentRoute === "/settings" ? colors.mint.lighter : null}
        >
          <View style={tailwind("h-6 w-6")}>
            <Icon name="settings" size={24} clearBackground />
          </View>
          {expanded && <Caption style={tailwind("text-gray-800 ml-2")}>Settings</Caption>}
        </NavItem>
      </TouchableOpacity> */}
    </Animated.View>
  );
}
